export default {
  methods: {
    getDayOffType(workday, showNotDayOff = true, time = '00:00') {
      if (workday.dsr_suppressed) {
        return `${this.$t('components.lists.mirrors.dsr_suppressed')} ${time}`;
      }
      if (workday.cct_respite) {
        return this.$t('components.lists.mirrors.cct');
      }
      if (workday.dsr_respite) {
        return this.$t('components.lists.mirrors.dsr');
      }
      if (workday.day_off) {
        return this.$t('components.lists.mirrors.day_off');
      }

      if (!showNotDayOff) return ' - ';

      return this.$t('components.lists.mirrors.regular_day');
    },
  },
};
