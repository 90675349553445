<template>
  <div>
    <p class="title">
      {{ $t("components.lists.mirrors.workday_list") }}
    </p>

    <div class="workday-times">
      <div
        v-if="!workday.dsr_suppressed"
        class="mt-4"
      >
        <p class="mb-0 times-hour">
          {{ getDayOffType(workday) }}
        </p>
        <p class="times-title">
          {{ $t('components.lists.mirrors.day_off_type') }}
        </p>
        <v-divider />
      </div>

      <div
        v-else
        class="mt-4"
      >
        <p class="mb-0 times-hour">
          {{ formatHour(workday.dsr_suppressed_time, false) }}
        </p>
        <p class="times-title">
          {{ $t('components.lists.mirrors.dsr_suppressed') }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.drive_time) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.drive") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.wait_time) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.wait") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.rest_time) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.rest") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.meal_time) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.meal") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.available_time) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.available") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.overnight_time) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.overnight") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.half_extra_hour) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.half_extra_hour") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.full_extra_hour) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.full_extra_hour") }}
        </p>
        <v-divider />
      </div>

      <div class="mt-4">
        <p class="mb-0 times-hour">
          {{ formatHour(workday.night_increase) }}
        </p>
        <p class="times-title">
          {{ $t("components.lists.mirrors.night_increase") }}
        </p>
      </div>
    </div>

    <v-btn
      v-if="!workday.is_last_workday"
      large
      color="accent"
      class="edit-button"
      @click="goToEditByManager"
    >
      {{ $t("components.lists.mirrors.edit") }}
    </v-btn>
  </div>
</template>

<script>
import formats from '@/mixins/formats';
import getDayOffType from '@/mixins/getDayOffType';

export default {
  name: 'WorkdayTimesList',
  mixins: [formats, getDayOffType],
  props: {
    workday: {
      type: Object,
      default: () => {},
    },
    driver: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    showUser(userId) {
      this.$emit('showUserDetails', userId);
    },
    goToEditByManager() {
      this.$router.push({
        name: 'mirrorListAsjustmentByManager',
        params: {
          workdayId: this.workday.id,
          driverId: this.driver.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.workday-times {
  width: 148px;

  .times-hour {
    font-family: 'Acumin-Pro-Bold';
    font-size: 24px;
    color: $color_primary;
  }

  .times-title {
    font-size: 14px;
    font-family: 'Acumin-Pro-Regular';
    color: $color_list_header;
  }
}

.edit-button {
  font-family: 'Fira-Sans-Bold';
  font-size: 14px;
  letter-spacing: 0;
  height: 42px;
  width: 156px;
}
</style>
