<template>
  <v-row
    class="header-frame"
    justify="space-between"
  >
    <v-col
      v-if="print"
      cols="12"
      sm="8"
    >
      <p class="header-name">
        {{ driver.name }} - {{ driver.cpf }}
      </p>
    </v-col>
    <v-col
      v-else
      cols="12"
      sm="8"
    >
      <p class="header-name">
        {{ driver.name }}
      </p>
      <p class="header-infos mb-0">
        {{ driver.cpf }}
      </p>
      <p class="header-infos">
        {{ driver.phone }}
      </p>
    </v-col>
    <v-col
      cols="12"
      sm="2"
    >
      <v-btn
        v-if="showAdvertence"
        large
        outlined
        color="accent"
        class="edit-button"
        @click="openAdvertence"
      >
        {{ $t("components.header.workday.btn.warning") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WorkdayHeader',
  props: {
    driver: {
      type: Object,
      default: () => ({
        name: '',
        cpf: '',
        phone: '',
      }),
    },
    showAdvertence: {
      type: Boolean,
      default: false,
    },
    print: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openAdvertence() {
      this.$emit('open');
    },
  },
};
</script>

<style lang="scss">
  .header-frame {
    .header-name {
      margin-bottom: 0;
      font-family: 'Fira-Sans-Bold';
      font-size: 20px;
      color: $color_list_header;
    }

    .header-infos {
      font-family: 'Acumin-Pro-Regular';
      font-size: 14px;
      color: $color_subtitle;
    }
  }

  @media print {
    .header-frame {
      page-break-before: always;

      .header-name {
        font-size: 12px;
      }

      .header-infos {
        font-size: 10px;
      }
    }
  }
</style>
