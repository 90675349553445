<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="730px"
      class="advertence-dialog"
      @click="changeDialog()"
    >
      <v-card class="px-16 py-8">
        <v-card-title class="dialog-title">
          {{ fetchText('title') }}
        </v-card-title>
        <v-card-subtitle class="dialog-subtitle">
          {{ fetchText('subtitle') }}
        </v-card-subtitle>
        <v-card-text>
          <ValidationProvider
            v-slot="{errors}"
            ref="refjustification"
            name="justificativa"
            rules="required"
            mode="passive"
          >
            <v-textarea
              v-model="justText"
              outlined
              name="input-7-4"
              background-color="color_background"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-select
            v-model="justSelect"
            :items="items"
            item-text="text"
            item-value="value"
            background-color="color_background"
            class="advertence-select"
            :label="fetchText('kind')"
            style="max-width:60%;"
            outlined
          />
          <v-btn
            color="accent"
            class="button pa-6"
            @click="sendAdvertence"
          >
            {{ fetchText('btn') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DriverDialog',
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    items: [],
    justText: '',
    justSelect: 'writing',
  }),
  watch: {
    openDialog() {
      this.changeDialog();
    },
  },
  mounted() {
    this.items = [
      { text: this.fetchText('items.writing'), value: 'writing' },
      { text: this.fetchText('items.verbal'), value: 'verbal' },
    ];
  },
  methods: {
    sendAdvertence() {
      this.$refs.refjustification.validate().then((response) => {
        if (response.valid) {
          this.$emit('sendAdvertence', this.justText, this.justSelect);
          this.changeDialog();
          this.clearDate();
        }
      });
    },
    clearDate() {
      this.justText = '';
      this.justSelect = 'writing';
    },
    changeDialog() {
      this.dialog = !this.dialog;
    },
    fetchText(param) {
      return this.$t(`components.dialogs.advertence.${param}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.advertence-dialog {
  .dialog-title {
    font-family: 'Fira-Sans-Bold';
  }
  .dialog-subtitle {
    font-family: 'Acumin-Pro-Regular';
  }
}
</style>
