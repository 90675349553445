<template>
  <div class="workday-update-box">
    <p class="title">
      {{ $t('components.lists.workday_update.title') }}
    </p>
    <div v-if="hasWorkdayUpdates">
      <div
        v-for="workdayUpdate in workdayUpdates"
        :key="workdayUpdate.id"
        class="workday-update-list mb-3"
        @click="goToAdjustment(workdayUpdate.id)"
      >
        <div class="px-6 py-2 update-frame">
          <p :class="updateStatusClass(workdayUpdate)">
            {{ formatDateISO(workdayUpdate.created_at, 'dd/MM') }}
          </p>
          <p class="mb-0 update-requester">
            {{ adjustmentText(workdayUpdate) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'WorkdayUpdateList',
  mixins: [formats],
  props: {
    workdayUpdates: {
      type: Array,
      default: () => [],
    },
    driver: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasWorkdayUpdates() {
      return this.workdayUpdates.length > 0;
    },
  },
  methods: {
    adjustmentText(workday) {
      const ptbrPath = 'components.lists.workday_update.requester';
      return this.$i18n.t(`${ptbrPath}.${workday.requester}.${workday.status}`);
    },
    updateStatusClass(workdayUpdate) {
      if (workdayUpdate.status === 'pending') {
        return 'mb-0 update-date is-pending';
      }

      return 'mb-0 update-date';
    },
    goToAdjustment(workdayUpdateId) {
      this.$router.push({ name: 'mirrorListAdjustment', params: { workdayUpdateId, userId: this.driver.id } });
    },
  },
};
</script>

<style lang="scss">
.workday-update-box {
  cursor: pointer;

  .workday-update-list {
    .update-frame {
      background-color: $color_white;
      color: $color_list_body;
      font-size: 14px;

      .update-date {
        margin-bottom: 0;
        font-family: 'Acumin-Pro-Bold';

        &.is-pending {
          color: $color_primary;
        }
      }

      .update-requester {
        font-family: 'Acumin-Pro-Regular'
      }
    }
  }
}
</style>
