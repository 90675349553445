var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('components.graphs.workday.title'))+" ")]),_c('div',{staticClass:"d-inline-flex graph-box"},[_c('div',{staticClass:"row-icons"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"large":"","color":"color_icon_dark"}},'v-icon',attrs,false),on),[_vm._v(" $vuetify.icons.custom_drive ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.lists.mirrors.drive'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"large":"","color":"color_icon_dark"}},'v-icon',attrs,false),on),[_vm._v(" $vuetify.icons.custom_wait ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.lists.mirrors.wait'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"large":"","color":"color_icon_dark"}},'v-icon',attrs,false),on),[_vm._v(" $vuetify.icons.custom_rest ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.lists.mirrors.rest'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"large":"","color":"color_icon_dark"}},'v-icon',attrs,false),on),[_vm._v(" $vuetify.icons.custom_meal ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.lists.mirrors.meal'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"large":"","color":"color_icon_dark"}},'v-icon',attrs,false),on),[_vm._v(" $vuetify.icons.custom_available ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.lists.mirrors.available'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"large":"","color":"color_icon_dark"}},'v-icon',attrs,false),on),[_vm._v(" $vuetify.icons.custom_overnight ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.lists.mirrors.overnight'))+" ")])])],1),_c('div',{staticClass:"d-block graph-area"},[_c('GChart',{ref:"gChart",attrs:{"settings":{ packages: ['timeline'] },"type":"Timeline","data":_vm.chartData,"options":_vm.chartOptions,"resize-debounce":500}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }