<template>
  <v-row justify="center">
    <v-dialog
      v-model="openDialog"
      persistent
      max-width="500"
    >
      <v-card class="dialog-card">
        <v-card-title>
          <h1 class="dialog-title">
            {{ $t('components.dialogs.workday_progress.title') }}
          </h1>
        </v-card-title>
        <v-card-text>
          <p class="dialog-text">
            {{ $t('components.dialogs.workday_progress.text') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="dialog-button"
            color="accent"
            @click="closeDialog()"
          >
            {{ $t('components.dialogs.workday_progress.buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'WorkdayProgress',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDialog: false,
    };
  },
  watch: {
    dialog(value) {
      this.openDialog = value;
    },
  },
  methods: {
    closeDialog() {
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-card {
  padding: 14px;

  .dialog-title {
    font-family: "Fira-Sans-Bold";
    font-size: 24px;
    color: $color_list_body;
  }

  .dialog-text {
    font-family: "Acumin-Pro-Regular";
    font-size: 16px;
  }

  .dialog-button {
    font-family: 'Fira-Sans-Bold';
    font-size: 16px;
    letter-spacing: 0;
    height: 42px;
    width: 140px;
  }
}
</style>
