<template>
  <div>
    <p class="title">
      {{ $t('components.graphs.workday.title') }}
    </p>

    <div class="d-inline-flex graph-box">
      <div class="row-icons">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="mt-1"
              color="color_icon_dark"
              v-bind="attrs"
              v-on="on"
            >
              $vuetify.icons.custom_drive
            </v-icon>
          </template>
          <span> {{ $t('components.lists.mirrors.drive') }} </span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="mt-1"
              color="color_icon_dark"
              v-bind="attrs"
              v-on="on"
            >
              $vuetify.icons.custom_wait
            </v-icon>
          </template>
          <span> {{ $t('components.lists.mirrors.wait') }} </span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="mt-1"
              color="color_icon_dark"
              v-bind="attrs"
              v-on="on"
            >
              $vuetify.icons.custom_rest
            </v-icon>
          </template>
          <span> {{ $t('components.lists.mirrors.rest') }} </span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="mt-1"
              color="color_icon_dark"
              v-bind="attrs"
              v-on="on"
            >
              $vuetify.icons.custom_meal
            </v-icon>
          </template>
          <span> {{ $t('components.lists.mirrors.meal') }} </span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="mt-1"
              color="color_icon_dark"
              v-bind="attrs"
              v-on="on"
            >
              $vuetify.icons.custom_available
            </v-icon>
          </template>
          <span> {{ $t('components.lists.mirrors.available') }} </span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="mt-1"
              color="color_icon_dark"
              v-bind="attrs"
              v-on="on"
            >
              $vuetify.icons.custom_overnight
            </v-icon>
          </template>
          <span> {{ $t('components.lists.mirrors.overnight') }} </span>
        </v-tooltip>
      </div>

      <div class="d-block graph-area">
        <GChart
          ref="gChart"
          :settings="{ packages: ['timeline'] }"
          type="Timeline"
          :data="chartData"
          :options="chartOptions"
          :resize-debounce="500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';

export default {
  name: 'WorkdayGraph',
  components: {
    GChart,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        avoidOverlappingGridLines: false,
        height: 300,
        width: 2000,
        hAxis: {
          format: 'HH:mm\ndd/MM',
        },
        colors: [
          '#FF4900',
          '#FF4900',
          '#979797',
          '#979797',
          '#979797',
          '#979797',
        ],
        timeline: {
          showBarLabels: false,
          showRowLabels: false,
          backgroundColor: '#fff',
        },
        tooltip: {
          trigger: 'none',
        },
      },
    };
  },

  computed: {
    chartData() {
      const types = [
        { type: 'string', label: 'Preslabelent' },
        { type: 'date', label: 'Start' },
        { type: 'date', label: 'End' },
      ];

      return [types, ...this.initialValueForGraph, ...this.eventsFormattedForGraph];
    },

    eventsFormattedForGraph() {
      /*
        retorna um array de arrays de eventos com os eventos
        ex:
        [
          [nome do evento, data/hora de inicio, data/hora de fim]
          [nome do evento, data/hora de inicio, data/hora de fim]
          [nome do evento, data/hora de inicio, data/hora de fim]
        ]
      */

      return this.events.flatMap((e) => {
        const startDate = this.fetchFormatDate(e.start_time, e.local_timezone);
        const endDate = this.fetchFormatDate(e.end_time, e.local_timezone);

        if (e.kind === 'rest_meal') {
          return [['rest', startDate, endDate], ['meal', startDate, endDate]];
        } if (e.kind === 'wait_meal') {
          return [['wait', startDate, endDate], ['meal', startDate, endDate]];
        } if (e.kind === 'wait_rest') {
          return [['wait', startDate, endDate], ['rest', startDate, endDate]];
        }

        return [[e.kind, startDate, endDate]];
      });
    },

    initialValueForGraph() {
      const time = this.fetchFormatDate(this.events[0].start_time, this.events[0].local_timezone);

      return [
        ['drive', time, time],
        ['wait', time, time],
        ['rest', time, time],
        ['meal', time, time],
        ['available', time, time],
        ['overnight', time, time],
      ];
    },
  },

  methods: {
    fetchFormatDate(dateTime) {
      // TODO: por enquanto estou ignorando o timezone que api está mandando
      const dateWithouthTimezone = dateTime.slice(0, 16);
      const date = new Date(dateWithouthTimezone);

      return date;
    },
  },
};
</script>

<style lang="scss">
.graph-box {
  max-width: 100%;

  .row-icons {
    width: 36px;
  }

  .graph-area {
    max-width: 100%;
    overflow-x: scroll;
  }
}
</style>
