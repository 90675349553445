<template>
  <gmap-map
    ref="mapRef"
    :center="positionInit"
    :zoom="mapZoom"
    map-type-id="roadmap"
    :options="options"
    class="shipment-map"
    @zoom_changed="changeZoom"
  >
    <map-marker
      v-for="(geoPoint, index) in unifiedLocationPoints"
      :key="index"
      :color-position="classColorPosition(geoPoint.index)"
      :marker="geoPoint"
    />

    <!-- <gmap-polyline
      :path="path"
      :options="{ strokeColor: '#FF4900' }"
    /> -->
  </gmap-map>
</template>

<script>
import MapMarker from '@/components/map/MapMarker.vue';

export default {
  name: 'WorkdayMap',
  components: {
    MapMarker,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    path: {
      type: Array,
      default: () => [],
    },
    geoPoints: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentZoom: 1,
      unifiedLocationPoints: [],
      position: {
        lat: -20.497520,
        lng: -54.608349,
      },
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: true,
        gestureHandling: 'greedy',

        styles: [
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
        ],
      },
      dialogTime: false,
    };
  },
  computed: {
    positionInit() {
      if (this.events[0]) {
        return {
          lat: parseFloat(this.events[0].position.lat),
          lng: parseFloat(this.events[0].position.lng),
        };
      }

      return { lat: -20.497520, lng: -54.608349 };
    },
    mapZoom() {
      return 12;
    },
  },
  watch: {
    currentZoom() {
      this.unifyLocationPoints();
    },

    geoPoints() {
      this.unifyLocationPoints();
    },

    events() {
      this.unifyLocationPoints();
    },
  },
  mounted() {
    this.changeZoom(this.mapZoom);

    this.unifyLocationPoints();
  },
  methods: {
    classColorPosition(eventIndex) {
      let color = '';
      if (eventIndex === 0) {
        color = 'start-point';
      } else {
        color = 'end-point';
      }

      return color;
    },
    changeZoom(args) {
      if (args >= 15) this.currentZoom = 1;

      this.currentZoom = 16 - args;
    },

    unifyLocationPoints() {
      let aux = 0;

      this.unifiedLocationPoints = this.geoPoints.map((point) => {
        if (aux === this.currentZoom) {
          aux = 0;
          return point;
        }

        aux += 1;
        return null;
      }).filter((element) => element !== null);

      this.unifiedLocationPoints.push(...this.events);
    },
  },
};
</script>
<style lang="scss" scoped>
  .shipment-map {
    width: 100%;
    height: calc(100vh - 110px);
  }

  @media (max-width: 1919px) {
    .shipment-map {
      height: calc(100vh - 80px);
    }
  }
</style>
