<template>
  <div class="event-container">
    <div class="event-box">
      <p class="title">
        {{ $t('components.lists.mirrors.title', { timezone_offset: timezoneOffset }) }}
      </p>
      <div
        v-for="(event, index) in events"
        :key="index"
        class="event-list"
      >
        <div
          v-if="verifyDate(event, index)"
          class="day mt-4"
        >
          {{ dayOfWeek(event.start_time, 'iii') }}
          {{ dayOfWeek(event.start_time, 'dd/MM') }}
        </div>
        <div
          :class="iconClass(event)"
          @click="openMap(index)"
        >
          <v-icon
            class="icon"
            left
            :color="iconColor(event)"
          >
            {{ mapIcon(event) }}
          </v-icon>
          <div
            class="event-info"
          >
            <p class="time">
              {{ formatDateTimeWithTimezone(event.start_time) }}
              <span
                v-if="event.lat == null"
                class="nil-location"
              >
                {{ $t('components.map.event_with_nil_location') }}
              </span>
            </p>
            <p class="kind">
              {{ $t(`components.lists.mirrors.${event.kind}`) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <map-dialog
      :dialog="dialog"
      :event="eventPoint"
      :event-next="eventNext"
      :geo-points="getGeoPoints"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formats from '@/mixins/formats';
import MapDialog from '@/components/dialogs/MapDialog.vue';

export default {
  name: 'EventList',
  components: {
    MapDialog,
  },
  mixins: [formats],
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    timezoneOffset: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      eventPoint: {},
      eventNext: {},
    };
  },
  computed: {
    ...mapGetters([
      'getGeoPoints',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchGeoPoints',
      'resetGeoPoints',
    ]),
    iconColor(event) {
      if (event.lat !== null) {
        return '';
      }

      return 'color_subtitle';
    },
    iconClass(event) {
      if (event.lat) {
        return 'event';
      }

      return 'nil-event-location';
    },
    mapIcon(event) {
      if (event.lat) {
        return 'mdi-map-search-outline';
      }

      return 'mdi-map-marker-off-outline';
    },
    verifyDate(event, index) {
      return (event.start_time === this.events[0].start_time)
              || (this.dayOfWeek(event.start_time, 'dd/MM')
              !== this.dayOfWeek(this.events[index - 1].start_time, 'dd/MM'));
    },
    openMap(index) {
      if (this.events[index].lat !== null) {
        this.ensureEventKind(this.events[index]);

        this.eventPoint = this.formatEvent(this.events[index], 0);
        this.eventNext = this.formatEvent(this.events[index + 1], 1);

        this.dialog = true;
      }
    },
    formatEvent(event, index) {
      if (!event) return {};

      return {
        index,
        events_total_length: this.events.length,
        kind: event.kind,
        position: {
          lat: event.lat,
          lng: event.lng,
        },
        time: event.start_time,
      };
    },
    ensureEventKind(event) {
      if (event.kind === 'overnight' || event.kind === 'meal') {
        this.resetGeoPoints();
      } else {
        this.fetchGeoPoints({
          event_id: event.id,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.event-box {
  display: block;

  .event-list {
    .day {
      font-size: 18px;
      color: $color_list_body;
      font-family: 'Acumin-Pro-Bold';
      text-transform: capitalize;
    }
    .event {
      background-image: linear-gradient($color_subtitle 50%, $color_white 0%);
      background-position: 2px;
      background-size: 2px 16px;
      background-repeat: repeat-y;
      height: 58px;
      width: 40%;
      margin-left: 10px;
      display: -webkit-box;
      cursor: pointer;

      .icon {
        background-color: $color_background;
        transform: translateX(-40%);
        color: $color_subtitle;
      }

      .event-info {
        display: block;
        font-size: 14px;

        .time {
          margin-bottom: 0;
          color: $color_primary;
          font-family: 'Acumin-Pro-Bold';

          .nil-location {
            font-family: 'Acumin-Pro-Regular';
            color: $color_subtitle;
            font-size: 12px;
          }
        }

        .kind {
          color: $color_list_body;
          font-family: 'Acumin-Pro-Regular'
        }
      }

      &:hover .icon {
        color: $color_primary;
      }
    }

    .nil-event-location {
      @extend .event;
      cursor: auto;
    }
    &:last-child {
      .event {
        background-image: none;
      }
    }
  }
}
</style>
