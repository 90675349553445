<template>
  <gmap-custom-marker
    :marker="currentPosition"
  >
    <div
      v-if="!isGeoPointMarker"
      :class="`map-marker ${colorPosition}`"
      @click="openTime()"
    >
      <v-icon
        v-for="kind in fetchIcon"
        :key="kind"
        large
        class="ma-0"
        color="white"
      >
        {{ kind }}
      </v-icon>
    </div>
    <div
      v-else
      @click="openTime()"
    >
      <v-badge
        color="#FF4900"
        overlap
        dot
      />
    </div>
    <span
      :class="`hour ${dialogClass}`"
      @click="openTime()"
    >
      {{ dateFormated }} {{ timeFormated }} {{ kindFormated }}
    </span>
  </gmap-custom-marker>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import formats from '@/mixins/formats';

export default {
  name: 'MapMarker',
  components: {
    GmapCustomMarker,
  },
  mixins: [formats],
  props: {
    colorPosition: {
      type: String,
      default: '',
    },
    marker: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dialogTime: false,
  }),
  computed: {
    isGeoPointMarker() {
      return this.marker.kind === 'geo_point';
    },
    dialogClass() {
      return this.dialogTime ? 'hour-on' : 'hour-off';
    },
    dateFormated() {
      return this.dayOfWeek(this.marker.time, 'dd/MM');
    },
    timeFormated() {
      if (this.marker.time) return this.formatDateTimeWithTimezone(this.marker.time);

      return '';
    },
    currentPosition() {
      if (!this.marker.position) return {};

      return {
        lat: this.formatCoordinate(this.marker.position.lat, 0),
        lng: this.formatCoordinate(this.marker.position.lng, 1),
      };
    },
    kindFormated() {
      if (this.isGeoPointMarker) {
        return '';
      }
      return this.$t(`components.map.event_kind.${this.marker.kind}`);
    },
    fetchIcon() {
      const kinds = this.marker.kind.split('_');
      if (kinds.length === 1) {
        return [`$vuetify.icons.custom_${this.marker.kind}`];
      }
      return [`$vuetify.icons.custom_${kinds[0]}`, `$vuetify.icons.custom_${kinds[1]}`];
    },
  },
  methods: {
    openTime() {
      this.dialogTime = !this.dialogTime;
    },
    formatCoordinate(value, position) {
      const coordinates = String(value).split(',');

      if (coordinates.length === 1) return parseFloat(value);

      return parseFloat(coordinates[position].replace(/\s/g, ''));
    },
  },
};
</script>

<style lang="scss" scoped>
.map-marker {
  height: 36px;
  border-radius: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  .marker-center {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  &.default-point {
    background: $color_primary;

    .marker-center {
      margin: 10px;
      background: $marker-default-point;
    }
  }
  &.start-point {
    background: $marker-start-point;

    .marker-center {
      background: $marker-start-point;
    }
  }

  &.end-point {
    background: $color_primary;

    .marker-center {
      background: $marker-default-point;
    }
  }
}
.hour {
  position: absolute;
  background-color: $marker-default-point;
  padding: 10px;
  color: white;
  width: 88px;
  border-radius: 8px;
}
.hour-off {
  display: none;
}
.hour-on {
  display: block;
}
</style>
