<template>
  <v-container>
    <div v-if="hasDriver && hasWorkday">
      <workday-header
        :show-advertence="true"
        :driver="getDriver"
        @open="changeAdvertenceDialog"
      />
      <advertence-dialog
        :open-dialog="dialogAdvertence"
        @sendAdvertence="sendAdvertence"
        @closeDialog="changeAdvertenceDialog"
      />
      <v-row>
        <v-col cols="3">
          <workday-times-list
            :workday="getWorkday"
            :driver="getDriver"
          />
        </v-col>

        <v-col cols="9">
          <workday-graph
            :events="getWorkday.events"
          />

          <v-row>
            <v-col cols="7">
              <event-list
                v-if="showEvents"
                :events="getWorkday.events"
                :timezone-offset="getWorkday.timezone_offset"
              />

              <div
                v-else
                class="mb-8"
              >
                <p class="title">
                  {{
                    $t('components.lists.mirrors.title',
                       {
                         timezone_offset: getWorkday.timezone_offset
                       }
                    )
                  }}
                </p>

                <div class="day mt-4">
                  {{ dayOfWeek(getWorkday.events[0].start_time, 'iii') }}
                  {{ dayOfWeek(getWorkday.events[0].start_time, 'dd/MM') }}
                </div>

                <div class="font-weight-black accent--text">
                  <v-icon
                    color="color_subtitle"
                    left
                    size="25"
                  >
                    mdi-map-marker-off-outline
                  </v-icon>
                  <span>
                    {{ getDayOffType(getWorkday) }}
                  </span>
                </div>
              </div>
            </v-col>

            <v-col cols="5">
              <workday-update-list
                :driver="getDriver"
                :workday-updates="getWorkdayUpdates"
              />
            </v-col>
            <v-btn
              v-if="showEvents"
              color="accent"
              @click="openMap()"
            >
              {{ $t('views.workday_show.btn.see_full_route') }}
            </v-btn>
            <map-dialog
              v-if="mapDialog"
              :full-route="true"
              :dialog="mapDialog"
              :events="mappedEvents"
              :geo-points="mappedGeoPoints"
            />
          </v-row>
        </v-col>
      </v-row>
      <workday-progress :dialog="dialog" />
    </div>
    <div
      v-else
      class="text-center accent--text font-weight-black"
    >
      {{ $t('views.workday_show.not_found') }}
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventList from '@/components/lists/EventList.vue';
import WorkdayGraph from '@/components/graphs/WorkdayGraph.vue';
import WorkdayTimesList from '@/components/lists/WorkdayTimesList.vue';
import WorkdayUpdateList from '@/components/lists/WorkdayUpdateList.vue';
import WorkdayHeader from '@/components/headers/WorkdayHeader.vue';
import AdvertenceDialog from '@/components/dialogs/AdvertenceDialog.vue';
import WorkdayProgress from '@/components/dialogs/WorkdayProgress.vue';
import MapDialog from '@/components/dialogs/MapDialog.vue';
import getDayOffType from '@/mixins/getDayOffType';
import formats from '@/mixins/formats';

export default {
  name: 'WorkdayShow',
  components: {
    EventList,
    WorkdayGraph,
    WorkdayTimesList,
    WorkdayUpdateList,
    WorkdayHeader,
    AdvertenceDialog,
    WorkdayProgress,
    MapDialog,
  },
  mixins: [getDayOffType, formats],
  data: () => ({
    dialogAdvertence: false,
    dialog: false,
    mappedEvents: null,
    mapDialog: false,
    workdayGeoPoints: null,
  }),
  computed: {
    ...mapGetters([
      'getWorkday',
      'getWorkdayUpdates',
      'getDriver',
      'getWorkdayGeoPoints',
    ]),

    hasDriver() {
      return this.getDriver.id != null;
    },

    hasWorkday() {
      return this.getWorkday.id != null;
    },

    showEvents() {
      return !this.getWorkday.day_off && !this.getWorkday.cct_respite && !this.getWorkday.dsr_respite;
    },

    textToDayOff() {
      return this.$t(`views.workday_show.${this.getWorkday.day_off ? 'day_off' : 'cct'}`);
    },

    // isNotProd() {
    //   return process.env.NODE_ENV !== 'production';
    // },
  },
  watch: {
    getWorkday(value) {
      if (value && value.is_last_workday) {
        this.dialog = true;
      }
    },
    getWorkdayGeoPoints(value) {
      if (value) {
        this.mapGeoPointsToMap();
      }
    },
  },
  mounted() {
    this.initLoading();
    this.fetchWorkday({ id: this.$route.params.workdayId });
    this.fetchWorkdayUpdates({ workday_id: this.$route.params.workdayId });
    this.fetchStoreDriver({ id: this.$route.params.userId });
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.point_mirror'),
        disabled: false,
        exact: true,
        to: {
          name: 'mirrorList',
        },
      },
      {
        text: this.$i18n.t('breadcrumbs.driver'),
        disabled: false,
        exact: true,
        to: {
          name: 'mirrorListShow',
          params: {
            id: this.$route.params.userId,
          },
        },
      },
      {
        text: this.$i18n.t('breadcrumbs.details_day'),
        disabled: true,
      },
    ]);
    // if (this.isNotProd) {
    this.fetchWorkdayGeoPoints({ id: this.$route.params.workdayId });
    // }
  },
  methods: {
    ...mapActions([
      'fetchWorkday',
      'fetchWorkdayUpdates',
      'fetchStoreDriver',
      'createAdvertence',
      'initLoading',
      'initCurrentBreadcrumbs',
      'fetchWorkdayGeoPoints',
    ]),
    changeAdvertenceDialog() {
      this.dialogAdvertence = !this.dialogAdvertence;
    },
    sendAdvertence(text, select) {
      this.createAdvertence(
        {
          advertence: { user_id: this.getDriver.id, justification: text, kind: select },
        },
      );
    },
    openMap() {
      this.mapEventsToMap();
      this.mapDialog = true;
    },
    mapEventsToMap() {
      const events = [];
      const eventsLength = this.getWorkday.events.length;
      this.getWorkday.events.forEach((event, index) => {
        events.push({
          index,
          events_total_length: eventsLength,
          kind: event.kind,
          position: {
            lat: event.lat,
            lng: event.lng,
          },
          time: event.start_time,
        });
      });
      this.mappedEvents = events;
    },
    mapGeoPointsToMap() {
      const geoPoints = [];
      const geoPointsLength = this.getWorkdayGeoPoints.length;
      this.getWorkdayGeoPoints.forEach((geoPoint, index) => {
        geoPoints.push({
          index,
          geo_points_total_length: geoPointsLength,
          kind: 'geo_point',
          position: geoPoint.position,
          time: geoPoint.time,
        });
      });
      this.mappedGeoPoints = geoPoints;
    },
  },
};
</script>

<style lang="scss" scoped>
.day {
  font-size: 18px;
  color: $color_list_body;
  font-family: 'Acumin-Pro-Bold';
  text-transform: capitalize;
}
</style>
