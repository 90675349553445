<template>
  <v-row
    class="mt-2"
  >
    <v-dialog
      v-model="dialog"
      max-width="1000"
    >
      <v-card>
        <div
          v-if="fullRoute"
          class="content-input"
        >
          <v-checkbox
            v-model="showRoute"
            class="ma-0 show-route"
            label="Mostrar rota"
          />
        </div>
        <workday-map
          v-if="fullRoute"
          :events="events"
          :geo-points="showRoute ? geoPoints : []"
          :path="showRoute ? path : []"
        />
        <route-map
          v-else
          :event-marker="event"
          :event-end-marker="eventEnd"
          :path="showRoute ? path : []"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import formats from '@/mixins/formats';
import RouteMap from '@/components/map/RouteMap.vue';
import WorkdayMap from '@/components/map/WorkdayMap.vue';

export default {
  name: 'MapDialog',
  components: {
    RouteMap,
    WorkdayMap,
  },
  mixins: [formats],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: () => {},
    },
    eventNext: {
      type: Object,
      default: () => {},
    },
    geoPoints: {
      type: Array,
      default: () => {},
    },
    fullRoute: {
      type: Boolean,
      default: () => false,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    path: [],
    showRoute: false,
  }),
  computed: {
    eventEnd() {
      return this.formatEndMarker(this.eventNext);
    },
  },
  watch: {
    geoPoints(points) {
      if (this.fullRoute) { return; }

      this.path = this.formatPath(points);
    },
  },
  mounted() {
    if (this.fullRoute) {
      this.path = this.formatPath(this.geoPoints);
    }
  },
  methods: {
    formatPath(points) {
      const allPositions = [];

      points.forEach((value) => {
        const position = {
          lat: this.formatCoordinate(value.position.lat, 0),
          lng: this.formatCoordinate(value.position.lng, 1),
        };

        allPositions.push(position);
      });

      return allPositions;
    },

    formatCoordinate(value, position) {
      const coordinates = String(value).split(',');

      if (coordinates.length === 1) return parseFloat(value);

      return parseFloat(coordinates[position].replace(/\s/g, ''));
    },

    formatEndMarker(endPoint) {
      if (!endPoint?.position) return {};

      return {
        index: this.event.index + 1,
        kind: endPoint.kind,
        time: endPoint.time,
        position: {
          lat: this.formatCoordinate(endPoint.position.lat, 0),
          lng: this.formatCoordinate(endPoint.position.lng, 1),
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.open-map {
  color: $color_primary;
}
.content-input {
  position: absolute;
  z-index: 4;
  padding: 8px 20px;
  border-radius: 8px;
  top: 100px;
  left: 10px;
  background-color: $color_white;
}
</style>
