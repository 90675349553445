<template>
  <gmap-map
    ref="mapRef"
    :center="positionInit"
    :zoom="mapZoom"
    map-type-id="roadmap"
    :options="options"
    class="shipment-map"
  >
    <map-marker
      :marker="eventMarker"
      :color-position="classColorPosition(eventMarker.index)"
    />
    <map-marker
      v-if="ensureEventEndMarker"
      :marker="eventEndMarker"
      :color-position="classColorPosition(eventEndMarker.index)"
    />
    <!-- <gmap-polyline
      :path="path"
      :options="{ strokeColor: '#FF4900' }"
    /> -->
  </gmap-map>
</template>

<script>
import MapMarker from '@/components/map/MapMarker.vue';

export default {
  name: 'Map',
  components: {
    MapMarker,
  },
  props: {
    eventMarker: {
      type: Object,
      default: () => {},
    },
    eventEndMarker: {
      type: Object,
      default: () => {},
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      position: {
        lat: -20.497520,
        lng: -54.608349,
      },
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: true,
        gestureHandling: 'greedy',

        styles: [
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    positionInit() {
      if (this.eventMarker) {
        return {
          lat: parseFloat(this.eventMarker.position.lat),
          lng: parseFloat(this.eventMarker.position.lng),
        };
      }

      return { lat: -20.497520, lng: -54.608349 };
    },
    mapZoom() {
      if (this.eventMarker.kind === 'drive') {
        return 7;
      }

      return 18;
    },
    ensureEventEndMarker() {
      if (this.eventEndMarker === null) {
        return false;
      }

      return true;
    },
  },
  methods: {
    classColorPosition(eventIndex) {
      let color = '';
      if (eventIndex === 0) {
        color = 'start-point';
      } else {
        color = 'end-point';
      }

      return color;
    },
    colorPolyline() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
  },
};
</script>
<style lang="scss" scoped>
  .shipment-map {
    width: 100%;
    height: calc(100vh - 110px);
  }

  @media (max-width: 1919px) {
    .shipment-map {
      height: calc(100vh - 80px);
    }
  }
</style>
